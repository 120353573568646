import { FC, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

interface INavProps {
	navStyles: string
	navUlStyles: string
	navLiStyles: string
	navLastLiStyles: string
	linkStyles: string
}

const Nav: FC<INavProps> = ({
	navStyles,
	navUlStyles,
	navLiStyles,
	navLastLiStyles,
	linkStyles,
}: INavProps) => {

	return (
		<>
			<nav className={navStyles}>
				<ul className={navUlStyles}>
					<li className={navLiStyles}>
						<NavLink to='/about' className={linkStyles}>
							About
						</NavLink>
					</li>
					<li className={navLiStyles}>
						<a
							href='https://imlaxutah.demosphere-secure.com/_registration_login?to=https://imlaxutah.demosphere-secure.com/_registration'
							target='_blank'
							rel='noreferrer'
							className={linkStyles}
						>
							Registration
						</a>
					</li>
					<li className={navLiStyles}>
						<NavLink to='/contact' className={linkStyles}>
							Contact
						</NavLink>
					</li>
					<li className={navLiStyles}>
						<NavLink to='/schedules' className={linkStyles}>
							Schedules
						</NavLink>
					</li>
					<li className={navLastLiStyles}>
						<NavLink to='/parentsinfo' className={linkStyles}>
							Parents
						</NavLink>
					</li>
				</ul>
			</nav>
		</>
	)
}

export default Nav

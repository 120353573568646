import React, { useEffect, useState } from 'react'
import Header from './Header'
import { IProps } from '../App'
import sanityClient from '../client'

const Schedules: React.FC<IProps> = ({ isNavOpen, handleNavClick }: IProps) => {
	const [schedulesData, setSchedulesData] = useState<any>(null)
	const [headerData, setHeaderData] = useState<any>(null)

	useEffect(() => {
		sanityClient
			.fetch(
				`
			*[_type == "schedules"] {
				contentTitle,
				addSchedules,
			}
		`
			)
			.then((data) => setSchedulesData(data[0]))
			.catch(console.error)

		sanityClient
			.fetch(
				`
					*[_type == 'headers' && headerId == 'schedules'] {
				headerTitle,
				headerSubTitle,
				buttonOneText,
				buttonTwoText,
				buttonOneLink,
				buttonTwoLink,
				showHeaderButtons,
					}
		`
			)
			.then((data) => setHeaderData(data[0]))
			.catch(console.error)
	}, [])

	if (!schedulesData || !headerData) {
		return <div>LOADING...</div>
	}

	return (
		<>
			<Header
				handleNavClick={handleNavClick}
				isNavOpen={isNavOpen}
				headerTitle={headerData.headerTitle}
				headerSubTitle={headerData.headerSubTitle}
				buttonOneText={headerData.buttonOneText}
				buttonOneLink={headerData.buttonOneLink}
				buttonTwoText={headerData.buttonTwoText}
				buttonTwoLink={headerData.buttonTwoLink}
				showHeaderButtons={headerData.showHeaderButtons}
			/>
			<div className='mx-28'>
				<div className='flex flex-col'>
					{
						//map fiunction here to iterate over the sched
						schedulesData.addSchedules.map((schedule: any) => {
							return (
								<div className='mb-12'>
									<h3 className='text-3xl pb-4 font-bold'>
										{schedule.scheduleTitle}
									</h3>
									<iframe
										src='https://leagueathletics.com/schedule.asp?teams=683253&org=imlaxutah.org'
										width='100%'
										height='500'
										title='Syracuse 7/8 Blue Schedule'
									></iframe>
								</div>
							)
						})
					}
				</div>
			</div>
		</>
	)
}

export default Schedules

import sanityClient from '../client';
import React, { useEffect, useState } from 'react';

const Sponsors: React.FC = () => {
	const [contentData, setContentData] = useState<any>(null);

	useEffect(() => {
		sanityClient
			.fetch(
				`
			*[_type == "sponsors"] {
				contentTitle,
				sponsorGallery[] {
                    'url': asset->url,
                    'id': asset->_id,
                },
			}
		`
			)
			.then((data) => setContentData(data[0]))
			.catch(console.error);
	}, []);

	if (!contentData) {
		return <div>LOADING...</div>;
	}
	return (
		<div className="flex flex-col items-center p-5 mb-40 bg-white border border-blue border-opacity-15 text-blue rounded-xxl">
			<h3 className="text-3xl pb-4 font-bold">{contentData.contentTitle}</h3>
			<div className="flex  md:text-left md:flex-row gap-6 items-center p-4">
				{contentData.sponsorGallery.map((s: any) => (
					<img
						key={s.id}
						src={`${s.url}`}
						alt={`sponsor-${s.id}`}
						className="w-3 rounded-lg"
					/>
				))}
			</div>
		</div>
	);
};

export default Sponsors;

import sanityClient from '../client'
import React, { useEffect, useState } from 'react'
import { IProps } from '../App'
import ContentHighlight from './ContentHighlight'
import ContentLeft from './ContentLeft'
import ContentRight from './ContentRight'
import Header from './Header'
import Sponsors from './Sponsors'

const Main: React.FC<IProps> = ({ isNavOpen, handleNavClick }: IProps) => {
	const [headerData, setHeaderData] = useState<any>(null)

	useEffect(() => {
		sanityClient
			.fetch(
				`
			*[_type == "headers" && headerId == "main"] {
				headerTitle,
				headerSubTitle,
				buttonOneText,
				buttonTwoText,
				buttonOneLink,
				buttonTwoLink,
				showHeaderButtons,
			}
		`
			)
			.then((data) => setHeaderData(data[0]))
			.catch(console.error)
	}, [])

	if (!headerData) {
		return <div>LOADING...</div>
	}

	return (
		<>
			<Header
				handleNavClick={handleNavClick}
				isNavOpen={isNavOpen}
				headerTitle={headerData.headerTitle}
				headerSubTitle={headerData.headerSubTitle}
				buttonOneText={headerData.buttonOneText}
				buttonOneLink={headerData.buttonOneLink}
				buttonTwoText={headerData.buttonTwoText}
				buttonTwoLink={headerData.buttonTwoLink}
				showHeaderButtons={headerData.showHeaderButtons}
			/>

			<main className='mx-4 md:mx-28'>
				<ContentLeft />
				<Sponsors />
				<ContentHighlight />
				<ContentRight />
			</main>
		</>
	)
}

export default Main

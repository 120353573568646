import React, { useEffect, useState } from 'react'
import Header from './Header'
import { IProps } from '../App'
import sanityClient from '../client'

const ParentsInfo: React.FC<IProps> = ({
	isNavOpen,
	handleNavClick,
}: IProps) => {
	const [parentsData, setParentsData] = useState<any>(null)
	const [headerData, setHeaderData] = useState<any>(null)

	useEffect(() => {
		sanityClient
			.fetch(
				`
			*[_type == "parents"] {
				contentTitle,
				addVideoLinks,
			}
		`
			)
			.then((data) => setParentsData(data[0]))
			.catch(console.error)

		sanityClient
			.fetch(
				`
					*[_type == 'headers' && headerId == 'parents'] {
				headerTitle,
				headerSubTitle,
				buttonOneText,
				buttonTwoText,
				buttonOneLink,
				buttonTwoLink,
				showHeaderButtons,
					}
		`
			)
			.then((data) => setHeaderData(data[0]))
			.catch(console.error)
	}, [])

	if (!parentsData || !headerData) {
		return <div>LOADING...</div>
	}

	return (
		<>
			<Header
				handleNavClick={handleNavClick}
				isNavOpen={isNavOpen}
				headerTitle={headerData.headerTitle}
				headerSubTitle={headerData.headerSubTitle}
				buttonOneText={headerData.buttonOneText}
				buttonOneLink={headerData.buttonOneLink}
				buttonTwoText={headerData.buttonTwoText}
				buttonTwoLink={headerData.buttonTwoLink}
				showHeaderButtons={headerData.showHeaderButtons}
			/>
			<div className='mx-28'>
				<h4 className='text-4xl text- text-center pb-10'>
					{parentsData.contentTitle}
				</h4>
				<div className='flex flex-col mb-24 justify-center items-center'>
					{parentsData.addVideoLinks.map((vl: any) => {
						return (
							<div className='mb-10 w-full'>
								<h6 className='text-2xl mb-5'>
									{vl.videoTitle}
								</h6>
								<iframe
									width={vl.width}
									height={vl.height}
									src={vl.videoUrl}
									title={vl.videoTitle}
									frameBorder='0'
									allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
									allowFullScreen
								></iframe>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default ParentsInfo
